
a {
    color: black;
    text-decoration: none;
}

@font-face {
    font-family: 'AGAvalancheC';
    src: url('../fonts/agavalanchec.eot'); /* IE 9 Compatibility Mode */
    src: url('../fonts/agavalanchec.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../fonts/agavalanchec.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/agavalanchec.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/agavalanchec.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/agavalanchec.svg#agavalanchec') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: 'AGAvalancheC-Bold';
    src: url('../fonts/agavalanchec-bold.eot'); /* IE 9 Compatibility Mode */
    src: url('../fonts/agavalanchec-bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../fonts/agavalanchec-bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/agavalanchec-bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/agavalanchec-bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/agavalanchec-bold.svg#agavalanchec-bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: 'AGAvalancheC-BoldItalic';
    src: url('../fonts/agavalanchec-bolditalic.eot'); /* IE 9 Compatibility Mode */
    src: url('../fonts/agavalanchec-bolditalic.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../fonts/agavalanchec-bolditalic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/agavalanchec-bolditalic.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/agavalanchec-bolditalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/agavalanchec-bolditalic.svg#agavalanchec-bolditalic') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: 'AGAvalancheC-Italic';
    src: url('../fonts/agavalanchec-italic.eot'); /* IE 9 Compatibility Mode */
    src: url('../fonts/agavalanchec-italic.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('../fonts/agavalanchec-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/agavalanchec-italic.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('../fonts/agavalanchec-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/agavalanchec-italic.svg#agavalanchec-italic') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
    font-family: "Transgender";
    src: url("../fonts/Transgender Grotesk-Regular-Trial.woff2") format("woff2"),
    url("../fonts/Transgender Grotesk-Regular-Trial.otf") format("otf"),
    url("../fonts/Transgender Grotesk-Regular-Trial.ttf") format("truetype"),
    url("../fonts/Transgender Grotesk-Regular-Trial.woff") format("woff");
    font-weight: normal;
}

:root {
    --primary: #e5f4f2;
    --primary-2: #40B296;
    --primary-3: #93B1AA;
    --darked-green: #457E6F;
    --darked-green-100: #568F7E;
    --grey: #E6E6E6;
    --main-green: #40B396;
    --dark-green: #0F413B;
    --dark: #0D3934;
    --animate-delay: 0.2s;
    --middle-green: #1B9473;
    --grey2: #8C8C8C;
}

* {
    box-sizing: border-box;
    font-family: 'AGAvalancheC', serif;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
}

body {
    /*position: relative;*/
    font-weight: bold;
    background: linear-gradient(90deg, var(--primary) 50%, white 50%);
}

body.overflow {
    overflow: hidden;
}

.container {
    width: 1600px;
    margin: 0 auto;
}

.wrapper-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 120px;
    z-index: 5;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-to-website {
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: white;
    margin-top: 30px;
    /*font-size: 12px;*/
    /*font-weight: 400;*/
    width: fit-content;
}

.logo-header {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
}

.scroll-down {
    position: absolute;
    right: -80px;
    bottom: 0;
}

.header-name-company {
    font-size: 14px;
    letter-spacing: 2.5px;
    line-height: normal;
    width: 150px;
    font-family: "Transgender", sans-serif;
}

.wrapper-header-logo {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.navbar {
    display: flex;

}

.navbar a {
    padding: 10px 20px;
    position: relative;
    font-weight: 600;
}

.navbar a:after {
    content: " ";
    height: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    position: absolute;
    border-right: 1px solid #000000;
}

.navbar > li {
    list-style-type: none;
}

.navbar > li:last-child > a:after {
    display: none;
}

.wrapper-menu-svg {
    position: relative;
    height: auto;
    min-height: 760px;
    width: 760px;
    margin: 0 auto;
}

.menu-svg {
    display: none;
    width: 760px;
    position: relative;

    left: 50%;
    transform: translateX(calc(-50% + 10px));
}

.menu-svg.active {
    display: flex;
}

.hidden-block {
    width: 160px;
    height: 160px;
    min-height: 160px;
    min-width: 160px;
    /*background: rgba(255, 0, 0, 0.43);*/
    position: absolute;
}

.hidden-block[data-menu="tech-park"] {
    top: 65px;
    left: 30px;
}

.hidden-block[data-menu="industrial-park"] {
    top: 130px;
    left: 610px;
}

.hidden-block[data-menu="recreate-park"] {
    top: 655px;
    left: 215px;
}

.wrapper-text-block {
    display: none;
    width: 65%;
    position: absolute;
    right: -360px;
    bottom: 200px;
    row-gap: 5px;
}

.wrapper-text-block.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.link-to-page {
    margin-top: 10px;
    font-size: 20px;
    line-height: normal;
}

.title-text-block {
    white-space: nowrap;
    font-size: 36px;
    font-weight: 600;
}

.desc-text-block {
    margin-top: 10px;
    line-height: normal;
}

.welcome-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /*margin-top: 100px;*/
    position: relative;
}

.wrapper-welcome-text {
    width: 50%;
    opacity: 0;
}

.company-welcome-title {
    font-size: 30px;
    font-weight: 600;
}

.desc-welcome-section {
    font-size: 30px;
    color: var(--main-green);
}

.sections-page {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 80px;
}

.block-circles {
    position: absolute;
    bottom: 40px;
}

.block-circles.left {
    left: 0;
}

.block-circles.right {
    right: 0;
}

.wrapper-double-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 75px;
    position: relative;
}

.inner-double-section {
    display: flex;
    opacity: 0;
}

.column-section {
    display: flex;
    align-items: center;
    width: 50%;
}

.text-section {
    font-size: 24px;
    line-height: normal;
}

.text-section.left,
.column-section.left {
    margin-right: 50px;
}

.text-section.right,
.column-section.right {
    margin-left: 50px;
}


.img-for-section {
    background-repeat: no-repeat;
    background-blend-mode: luminosity;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center center;
    height: 400px;
    width: 100%;
}


.wrapper-double-section:first-child {
    position: relative;
}

.line {
    position: relative;
    width: 100%;
    border-bottom: 2px solid var(--main-green);
}

.line:after, .line:before {
    position: absolute;
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: -5px;
    background: white;
    border: 2px solid var(--main-green);
}

.line:after {
    right: 0;
}

.line:before {
    left: 0;
}

.wrapper-news {
    display: flex;
    justify-content: space-between;
    /*min-height: 400px;*/
}

.news {
    width: calc((100% / 3) - 50px);
}

.inner-news {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    min-height: 280px;
}

.date-news {
    font-size: 24px;
    color: var(--darked-green);
}

.text-news {
    font-size: 18px;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
}

.wrapper-more {
    display: flex;
    align-items: center;
    column-gap: 15px;
    font-size: 20px;
    line-height: normal;
}

.wrapper-more:hover,
.link-to-page:hover {
    animation: rightMove 1s;
}

/*.last-section-page {*/
/*    padding-bottom: 100px;*/
/*}*/

.last-section-page {
    padding: 50px 0;
}

.last-section-page .inner-double-section {
    opacity: 1;
}

#map {
    width: 600px;
    height: 500px;
}

.wrapper-contacts {
    width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.contacts {
    justify-content: flex-end;
}

.block-contact {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.title-contact {
    font-size: 20px;
    font-weight: 600;
    color: var(--darked-green)
}

.info-contact {
    font-size: 16px;
    line-height: normal;
}

.text-for-arrow-down {
    transform: rotate(-90deg);
    /*width: 10px;*/
    white-space: nowrap;
}

.wrapper-arrow-down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 30px;
    bottom: 100px;
}

.slide-down{
    transition: all 1s ease-in-out;
    animation: arrowDown 1s linear infinite;
}

.icon-burger {
    width: 30px;
    height: 30px;
}

.wrapper-menu-burger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 5;
    height: 100%;
}

.wrap-header {
    position: relative;
}

#menu-burger {
    display: none;
}

#menu-burger.active {
    display: block;
}

.header-burger-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 0 20px 20px;*/
    margin-bottom: 20px;
}

.slinky-theme-default a:not(.back):hover {
    background: var(--primary);
}

#burger-for-open {
    display: none;
}

/*#sect1 {
    background-size: cover !important;
    background: linear-gradient(0, rgba(15, 65, 59, 0.35) 0%, rgba(15, 65, 59, 0.35) 100%), url("../img/image 300.png") no-repeat center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#sect2 {
    background-size: cover !important;
    background: linear-gradient(0, rgba(15, 65, 59, 0.35) 0%, rgba(15, 65, 59, 0.35) 100%), url("../img/image 29.png") no-repeat center;
}

#sect3 {
    background-size: cover !important;
    background: linear-gradient(0, rgba(15, 65, 59, 0.35) 0%, rgba(15, 65, 59, 0.35) 100%), url("../img/image 3012.png") no-repeat center;
}*/

.main-logo-lpm {
    display: none;
    width: 70%;
}

.main-logo-lpm.active {
    display: block;
}

.lpm-logo {
    position: absolute;
    bottom: 0;
    right: 0;
}

.title-section-lpm {
    text-shadow: 0px 4px 20px #000;
    color: white;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
}

.video-lpm {
    position: absolute;
    top: 0;
    left: -1px;
    z-index: -1;
    width: calc(100% + 2px);
    height: 100%;
    object-fit: cover;
}

.video-lpm.black-white {
    filter: grayscale(100%);
}

.description-content {
    overflow: hidden;
    height: 0;
    display: none;
    color: white;
    text-shadow: 0px 4px 20px #000;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1px;
    font-weight: 400;
}

.description-content.active {
    display: block;
    height: auto;
}

.wrapper-main-section {
    display: flex;
    overflow: hidden;
    /*border-radius: 36px;*/
    margin-bottom: 75px;
    height: 600px;
}

.section-lpm {
    position: relative;
    overflow: hidden;
    width: calc(100% / 3);
}

.section-lpm .new-link-to-video {
    /*border-radius: 50%;*/
    padding: 8px;
    z-index: 3;
}

.section-lpm:hover .text-play-video {
    display: block;
}

.section-lpm:hover .new-link-to-video {
    border-radius: 24px;
}



.section-lpm-block {
    padding: 80px 50px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.47);
    z-index: 3;
    position: relative;
}

.section-lpm-block:hover .sot {
    opacity: 0;
}

.text-play-video {
    color:  rgb(180, 180, 180);
    display: none;
}

.new-link-to-video {
    background: rgba(0, 0, 0, 0.5);
    transition: all .2s ease-in-out;
    position: absolute;
    bottom: 20px;
    right: 20px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    display: flex;
    column-gap: 10px;
    width: fit-content;
    padding: 8px 15px;
    border-radius: 24px;
    border: 1px solid rgba(180, 180, 180, 0.50);
}

.sot {
    opacity: 1;
    width: 550px;
    position: absolute;
    bottom: -120px;
    left: -80px;
    transition: opacity .5s ease-in-out;
}

.img-section-lpm {
    display: none;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: center;
    filter: grayscale(100%);
}

.img-section-lpm.active {
    display: flex;
}

.wrapper-documents {
    margin-top: 50px;
    display: flex;
    row-gap: 15px;
    column-gap: 35px;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 35px;
}

.document {
    text-align: center;
    text-decoration: underline;
    color: var(--primary-3);
}

.parag {
    margin-top: 15px;
}

.link-to-doc {
    text-decoration: underline;
    color: blue;
}

#circles2 {
    bottom: -20px;
}

.wrapper-safework {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 25px;
}

.wrapper-files {
    width: 50%;
    padding-bottom: 50px;
}

.wrapper-error {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.status-error {
    font-size: 200px;
    margin-bottom: 10px;
}

.subtitle-bold {
    font-size: 40px;
}

.return-to-main-page {
    margin-top: 15px;
    border: 1px solid black;
    padding: 10px;
    transition: all .2s ease-in-out;
}

.return-to-main-page:hover {
    background: black;
    color: white;
}

.title-main {
    font-size: 25px;
    font-weight: bold;
    font-family: "RobotoSlab-Bold", sans-serif;
}

.wrapper-vacancy {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    row-gap: 50px;
}

.list-vacancy {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.title-vacancy {
    color: var(--main-green);
    text-decoration: underline var(--main-green);
    font-family: "RobotoSlab-Regular", sans-serif;
}

.no-vacancy {
    font-family: "RobotoSlab-Regular", sans-serif;
}

.wrap-for-vacancy {
    padding-bottom: 100px;
}

.link-go-back {
    width: fit-content;
    font-family: "RobotoSlab-Regular", sans-serif;
    display: flex;
    margin: 25px 0;
    align-items: center;
    column-gap: 15px;
}

.wrapper-features {
    display: flex;
    flex-direction: column;
}

.wrapper-features span {
    font-family: "RobotoSlab-Regular", sans-serif;
}

.wrapper-features span > strong {
    font-family: "RobotoSlab-Regular", sans-serif;
}

.wrapper-features ul > li,
.wrapper-features ol > li
{
    font-family: "RobotoSlab-Regular", sans-serif;
}

strong {
    font-weight: bold;
}

.feature-vacancy {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.title-feature {
    color: var(--main-green);
    font-weight: bold;
    font-size: 18px;
}

.content-vacancy {
    font-size: 18px;
}

.green-btn {
    outline: none;
    border: none;
    padding: 15px 60px;
    background: var(--middle-green);
    color: white;
    cursor: pointer;
    font-family: "RobotoSlab-Regular", sans-serif;
}

.wrapper-respond {
    margin-top: 100px;
    padding-bottom: 100px;
    display: flex;
    width: 100%;
    justify-content: center;
}

@media (max-width: 1599.98px) {
    .container {
        width: 1400px;
    }

    .scroll-down {
        display: none;
    }

    .sot {
        width: 480px;
        bottom: -130px;
        left: -40px;
    }

    .description-content {
        font-size: 15px;
    }
}

@media (max-width: 1399.98px) {
    .container {
        width: 1200px;
    }

    .wrapper-contacts {
        width: 90%;
    }

    .wrapper-main-section {
        flex-direction: column;
        height: initial;
    }

    .section-lpm {
        width: 100%;
        /*height: 350px;*/
    }

    .sot {
        display: none;
        opacity: 0;
    }

    .img-section-lpm {
        filter: none;
    }

    .description-content {
        font-size: 16px;
    }
}

@media (max-width: 1199.98px) {
    .container {
        width: 992px;
    }

    .wrapper-contacts {
        width: 100%;
    }

    .block-contact {
        text-align: center;
    }

    .navbar {
        display: none;
    }

    .header {
        align-items: center;
    }

    .wrapper-welcome-text {
        width: 100%;
    }

    .inner-double-section {
        flex-direction: column;
        row-gap: 15px;
    }

    .column-section {
        width: 100%;
    }

    #circles1, #circles2 {
        display: none;
    }

    .text-section.left, .column-section.left,
    .text-section.right, .column-section.right {
        margin: 0;
    }

    body {
        background: var(--primary);
    }

    .wrapper-news {
        flex-wrap: wrap;
        row-gap: 35px;
        justify-content: space-around;
    }

    .news {
        width: calc((100% / 2) - 50px);
    }

    .inner-news {
        min-height: 280px;
    }

    #map {
        width: 100%;
        height: 500px;
    }

    .inner-double-section {
        width: 100%;
    }

    .company-welcome-title,
    .desc-welcome-section {
        font-size: 24px;
    }

    .text-section {
        font-size: 20px;
    }

    #burger-for-open {
        display: block;
    }

    .wrapper-files {
        width: 100%;
    }
}

@media (max-width: 991.98px) {

    .container {
        width: 768px;
    }

    .wrapper-double-section {
        row-gap: 35px;
    }

    .text-section {
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .container {
        width: 576px;
    }

    .news {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .inner-news {
        min-height: fit-content;
    }

    .company-welcome-title, .desc-welcome-section {
        font-size: 18px;
    }

    .text-news,
    .wrapper-more {
        font-size: 16px;
    }

    .date-news {
        font-size: 20px;
    }

    .wrapper-contacts {
        row-gap: 30px;
    }

    .title-contact {
        font-size: 18px;
    }

    .info-contact {
        font-size: 14px;
    }

    .sections-page {
        row-gap: 30px;
    }

    .status-error {
        font-size: 120px;
    }

    .subtitle-bold {
        font-size: 25px;
    }

    .text-section {
        font-size: 16px;
    }

}

@media (max-width: 575.98px) {

    .container {
        width: calc(100% - 20px);
        margin: 0 auto;
    }

    .logo-header {
        width: 35px;
        height: 35px;
        min-height: 35px;
        min-width: 35px;
    }

    .header-name-company {
        font-size: 10px;
    }

    .header-burger-menu {
        width: calc(100% - 20px);
        padding: 0 10px;
    }

    .wrapper-error {
        margin-top: 60px;
    }

    .status-error {
        font-size: 80px;
    }

    .subtitle-bold {
        font-size: 20px;
    }

    /*.wrapper-menu-burger.active  .header {*/
    /*    margin: 0 10px;*/
    /*}*/
}

@keyframes arrowDown {
    0% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(20px);
    }
}

@keyframes rightMove {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(20px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes fadeInDown {
    0% {
        transform: translate3d(0, -50px, 0);
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        transform: translate3d(50px, 0, 0);
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    0% {
        transform: translate3d(-50px, 0, 0);
    }

    100% {
        opacity: 1;
    }
}







